import React from 'react';
import {  Modal,Box ,useMediaQuery } from '@mui/material';


const ScreenModal = ({  open, handleClose, maxWidth = '900px',
    height = '100vh',
    maxHeight = '80vh', children }) => {
    const isSmallScreen = useMediaQuery('(max-width: 800px)'); // Adjust screen size for responsiveness
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="product-modal-title"
        aria-describedby="product-modal-description"
      >
        <Box 
      // sx={{ width: '100%', height: '100vh' }}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          height: height,
          transform: 'translate(-50%, -50%)',
          width:  '90%',
          maxWidth: maxWidth,
          backgroundColor: 'white',
          boxShadow: 24,
          padding: '20px',
          borderRadius: '10px',
          maxHeight: isSmallScreen ? '90%' : maxHeight,  // Limit the height of the modal
          overflowY:  'auto',  // Vertical scroll for small screens, horizontal for large
          overflowX:  'auto',
        }} >
            {children}
        </Box>

    {/* <div className="dialog-overlay" >
        <div className="dialog-box">
          <button className="dialog-close" onClick={handleClose}>
            &times;
          </button>
          {children}
        </div>
      </div> */}
     </Modal>
    );
  };

  // Dialog.js


  export default  ScreenModal

//   const Dialog = ({ isOpen, onClose, children }) => {
//     if (!isOpen) return null;
  
//     return (
//       <div className="dialog-overlay">
//         <div className="dialog-box">
//           <button className="dialog-close" onClick={onClose}>
//             &times;
//           </button>
//           {children}
//         </div>
//       </div>
//     );
//   };
  
//   export default Dialog;