import React, { useState, useEffect , useRef , } from 'react';

import { Box,  Typography, Button, AppBar, Toolbar, TextField, IconButton,
 } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



const ChatWindow = ({storeModel, isVisible, closeChat }) => {
    const chatRef = useRef(null);
    const [messages, setMessages] = useState(Array.from({ length: 100 }, (_, index) => ({
      text: `Test message ${index + 1}`,
      sender: index % 2 === 0 ? 'me' : 'other', // Simulate alternating between "me" and "other"
    })));
    const [newMessage, setNewMessage] = useState('');
  
    // Handle sending a message
    const handleSendMessage = () => {
      if (newMessage.trim()) {
        setMessages((prevMessages) => [...prevMessages, { text: newMessage.trim(), sender: 'me' }]);
        setNewMessage(''); // Clear input field after sending message
      }
    };
  
    // Scroll to the bottom of the chat when a new message is added
    useEffect(() => {
      const chatBox = chatRef.current;
      if (chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    }, [messages]);
  
    // Handle clicks outside of the chat window to close it
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (chatRef.current && !chatRef.current.contains(event.target)) {
          closeChat();
        }
      };
  
      if (isVisible) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isVisible, closeChat]);
  
    // Return nothing if chat is not visible
    if (!isVisible) return null;
  
    return (
      <Box
        ref={chatRef}
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '350px',
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0,0,0,0.3)',
          borderRadius: '10px 10px 0 0',
          zIndex: 1000,
          animation: 'slide-up 0.3s ease-out',
          '@keyframes slide-up': {
            from: {
              transform: 'translateY(100%)',
            },
            to: {
              transform: 'translateY(0)',
            },
          },
        }}
      >
        {/* Chat Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px',
            backgroundColor: 'primary',
            // color: 'white',
            borderRadius: '10px 10px 0 0',
          }}
        >
          
          <AppBar position="static" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <img src= {storeModel.logo} height={40} width={40} 
            alt="Logo" style={{marginRight: '6px', borderRadius: '10px' }} />
            {storeModel.nameT['en']} 
          {/* <div>{storeModel.username}</div> */}
          </Typography>
          
          <IconButton onClick={closeChat} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        
        </AppBar>
          
        </Box>
  
        {/* Scrollable Chat Messages */}
        <Box
          sx={{
            height: '300px',
            overflowY: 'auto',
            padding: '10px',
            backgroundColor: '#f9f9f9',
            borderTop: '1px solid #ddd',
          }}
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: msg.sender === 'me' ? 'flex-end' : 'flex-start',
                marginBottom: '5px',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  backgroundColor: msg.sender === 'me' ? '#d1e7dd' : '#f0f0f0', // Different background for user and others
                  padding: '8px 12px',
                  borderRadius: '10px',
                  maxWidth: '70%',
                  wordWrap: 'break-word',
                }}
              >
                {msg.text}
              </Typography>
            </Box>
          ))}
        </Box>
  
        {/* Message Input and Send Button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderTop: '1px solid #ddd',
            backgroundColor: '#f1f1f1',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Type a message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            sx={{ marginRight: '10px' }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage(); // Send message on Enter key press
              }
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSendMessage}>
            Send
          </Button>
        </Box>
      </Box>
    );
  };

  export default ChatWindow