import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Autocomplete, Marker } from '@react-google-maps/api'; // Use Marker here
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const libraries = ['places'];

const MapWithLocation = () => {
  const [currentPosition, setCurrentPosition] = useState({ lat: 30.5951, lng: 32.2715 });
  const [map, setMap] = useState(null);
  const searchBoxRef = useRef(null); // Use ref for the autocomplete instance
  const [placeName, setPlaceName] = useState('');

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ lat: latitude, lng: longitude });
      },
      () => {
        console.error('Error fetching the user location');
      }
    );
  }, []);

  const handleLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  // Handle place selection
  const handlePlaceChanged = () => {
    const searchBox = searchBoxRef.current; // Access the ref for the autocomplete instance
    if (searchBox) {
      const place = searchBox.getPlace();
      if (place && place.geometry) {
        console.log('There is an address founded')
        const { lat, lng } = place.geometry.location;
        setCurrentPosition({ lat: lat(), lng: lng() });
        setPlaceName(place.formatted_address || place.name);
      } else {
        console.log('There is no address founded')
        console.log(place)
      }
    } else {
      console.error('Autocomplete instance is not initialized');
    }
  };

  const handleConfirm = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(firestore, 'users', user.uid);
      try {
        await updateDoc(userDocRef, {
          location: {
            address: placeName,
            coordinates: currentPosition,
          },
        });
        alert('Location saved successfully!');
      } catch (error) {
        console.error('Error saving location:', error);
        alert('Failed to save location. Please try again.');
      }
    } else {
      alert('You need to be logged in to save an address.');
    }
  };
  const Googl_Key = "AIzaSyCkDy6xPJ7X8awhoO31_dcjde54BKHixwc";
  return (
    <LoadScript googleMapsApiKey={Googl_Key} libraries={libraries}>
      <div style={{ height: '100vh', width: '100%', position: 'relative' }}>
        {/* Search Box */}
        <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
          <Autocomplete
            onLoad={(autocompleteInstance) => {
              searchBoxRef.current = autocompleteInstance; // Store the instance in ref
            }}
            onPlaceChanged={handlePlaceChanged}
          >
            <input
              type="text"
              placeholder="Search your location"
              style={{
                width: '80vw',
                maxWidth: '500px',
                height: '40px',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              }}
            />
          </Autocomplete>
        </div>

        {/* Google Map */}
        <GoogleMap
          center={currentPosition}
          zoom={15}
          onLoad={handleLoad}
          mapContainerStyle={{ height: '100%', width: '100%' }}
        >
          {/* Traditional Marker */}
          <Marker position={currentPosition} />
        </GoogleMap>

        {/* Confirm Button */}
        <button
          onClick={handleConfirm}
          style={{
            width: '100%',
            height: '50px',
            backgroundColor: '#ffc107',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px',
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
          }}
        >
          Confirm
        </button>
      </div>
    </LoadScript>
  );

};

export default MapWithLocation;
