import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';  // Ensure Firebase config is imported
import { v1 as uuidv1 } from 'uuid';
import PackageModel from '../model/package'

// Create the context
export const AppContext = createContext();

// Create the provider component
export const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,       // Example user data
    userModel: null,
    needLst: {},         // Example cart for an e-commerce app
    theme: 'light',
    selectedOrderType:"Deliver"   // Example theme state
  });
  const [needLst, setNeedLst] = useState({});

  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [checkOutStoreProduct, setStoreCheckOut] = useState(false);
  const auth = getAuth();  // Initialize Firebase auth

  // Auto-detect the current active user and fetch data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, fetch user data from Firestore
        const userDoc = doc(firestore, 'customers', user.uid);
        const userData = await getDoc(userDoc);

        const userCartDoc = doc(firestore, 'needLst', user.uid);
        const userCartData = await getDoc(userCartDoc);

        if (userData.exists()) {
          const userCart = userCartData.data() || {};

          setState((prevState) => ({
            ...prevState,
            user: user,
            userModel:userData.data(),
            needLst: userCart,
          }));
          setNeedLst(userCart)
        } else {
          console.log('No such user data found in Firestore!');
        }
      } else {
        // User is signed out, reset the state
        setState({
          userModel:null,
          user: null,
          needLst: {},
          theme: 'light',
        });
        setNeedLst({})
      }
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [auth]);

  // Function to update user data in the state
  const updateUser = (newUser) => {
    setState((prevState) => ({
      ...prevState,
      user: newUser,
    }));
  };

  // Function to update the cart and write it to Firestore
  const updateCart = async (storeId, orderType, newCartItem) => {
    const updatedCart = [...state.needLst, newCartItem];

    // 
    const storeProductsO = state.needLst.storeProductsOL[0];
    // needLst.storeProductsOL = 

    // Update state
    setState((prevState) => ({
      ...prevState,
      needLst: updatedCart,
    }));
    setNeedLst(updatedCart)

    // Write the updated cart to Firestore if a user is logged in
    if (state.user) {
      const userDocRef = doc(firestore, 'users', state.user.uid);
      await setDoc(userDocRef, { cart: updatedCart }, { merge: true });
    }
  };

  const updateStoreProductOQuantity = async (storesModel, theProducts, { quantity, note, merge }) => {
    if ((storesModel?.id ?? "") !== "") {
      quantity = quantity ?? 1;
      note = note ?? "";
      merge = merge ?? true;
  
      const products = PackageModel.fromJson(theProducts, theProducts.id);

      let needLstModel = state.needLst??{};
      let selectedOrderType = state.selectedOrderType??"";
      let selectedDineInTable = null;
      let newSelectType = "Deliver";
  
      if (storesModel?.stOrderType?.length > 0) {
        selectedOrderType = storesModel?.stOrderType ?? "Deliver";
        newSelectType = selectedOrderType;
        if (newSelectType.toLowerCase().includes('dine in')) {
          selectedDineInTable = storesModel?.stTable ?? 1;
        }
      }
  
      (storesModel?.onlineOptionsL ?? []).forEach((element) => {
        const cleanedElement = (element??"").toString().toLowerCase();
        const cleanedOrderType = selectedOrderType.replaceAll("_", " ").toLowerCase();
  
        if (cleanedElement.includes(cleanedOrderType)) {
          newSelectType = element??"";
        }
      });
  
      if ((selectedOrderType ?? "") === '') {
        selectedOrderType = storesModel?.onlineOptionsL?.[0] ?? "Deliver";
        newSelectType = selectedOrderType??"";
      }
  
      let table = null;
      if (newSelectType.toLowerCase().includes('dine in')) {
        table = selectedDineInTable;
      }
  
      if (storesModel?.id && needLstModel && products) {
        let stL = needLstModel.storeProductsOL ?? [];
        let foundStore = false;
  
        for (const st of stL) {
          if (st?.storeId === storesModel?.id) {
            foundStore = true;
  
            let foundProduct = false;
            const product = st.productsOL?.find((element) => element.id === products.itemID);
  
            // If the product is found, modify it
            if (product) {
              if (merge) {
                foundProduct = true;
                product.quantity = (product.quantity ?? 0) + quantity;
                
                // If the product's quantity is now 0 or less, remove it
                if (product.quantity <= 0) {
                  st.productsOL = st.productsOL.filter((p) => p.id !== product.id);
                }
              }
            }
  
            // If no product was found and merge is false, add the new product
            if (!foundProduct) {
              let p = products.toProductO();
               p = { ...p, note: note ?? "", quantity };
              if (!merge) {
                p.uuid = uuidv1();
              }
              st.productsOL.push(p);
            }
  
            // Check if this store now has no products and remove it
            if (st.productsOL.length === 0) {
              stL = stL.filter((store) => store.storeId !== st.storeId);
            }
          }
        }
  
        // If no store was found, add the store with the product
        if (!foundStore) {
          let p = products.toProductO();
          p = { ...p, note: note ?? "", quantity };
          if (!merge) {
            p.uuid = uuidv1();
          }
          let stOO = {
            storeId: storesModel?.id,
            num: (needLstModel?.storeProductsOL?.length ?? 0) + 1,
            productsOL: [p],
            orderType: newSelectType,
            table: table,
          };
          stL.push(stOO);
        }
  
        needLstModel.storeProductsOL = stL;
  
        setState((prevState) => ({
          ...prevState,
          needLst: needLstModel,
        }));
        setNeedLst(needLstModel)
  
        // Write the updated cart to Firestore if a user is logged in
        if (state.user) {
          const userDocRef = doc(firestore, 'needLst', state.user.uid);
          await setDoc(userDocRef, needLstModel, { merge: true });
        }
      }
    }
  };
  

  
  // Function to change theme
  const changeTheme = (newTheme) => {
    setState((prevState) => ({
      ...prevState,
      theme: newTheme,
    }));
  };

  // The value provided will include both the state and the updater functions
  return (
    <AppContext.Provider
      value={{
        state,
        needLst,
        updateUser,
        updateCart,
        changeTheme,
        updateStoreProductOQuantity,
        checkoutVisible,
        setCheckoutVisible,
        setStoreCheckOut
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
