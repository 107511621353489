
class PackageModel {
    constructor({
      documentId,
      itemID,
      nameT,
      avPrice,
      img,
      serves,
      ingredientsTL,
      specsL,
      pCatL,
      tags,
      chefId,
      chefName,
      premium,
      cookTime,
      lastUpdate,
      flex,
      hasVideo,
      lang,
      status,
      featured,
      inPackages,
      comment,
      comments,
      calories,
      likes = 0,
      leverage,
      sortingV,
      source,
      views,
      collectionSource,
      storeId,
      position,
      price,
      priceB,
      discount,
      active,
      orderQuantity = 0,
      specsN,
      shopTypeL,
      type,
      componentsOL = [],
      descriptionT,
      recipeStepsT,
      otherComponentsOL = [],
      videoLink,
      addOnOptionsL = [],
      priceOptions,
    }) {
      this.documentId = documentId;
      this.itemID = itemID;
      this.nameT = nameT;
      this.avPrice = avPrice;
      this.img = img;
      this.serves = serves;
      this.ingredientsTL = ingredientsTL;
      this.specsL = specsL;
      this.pCatL = pCatL;
      this.tags = tags;
      this.chefId = chefId;
      this.chefName = chefName;
      this.premium = premium;
      this.cookTime = cookTime;
      this.lastUpdate = lastUpdate;
      this.flex = flex;
      this.hasVideo = hasVideo;
      this.lang = lang;
      this.status = status;
      this.featured = featured;
      this.inPackages = inPackages;
      this.comment = comment;
      this.comments = comments;
      this.calories = calories;
      this.likes = likes;
      this.leverage = leverage;
      this.sortingV = sortingV;
      this.source = source;
      this.views = views;
      this.collectionSource = collectionSource;
      this.storeId = storeId;
      this.position = position;
      this.price = price;
      this.priceB = priceB;
      this.discount = discount;
      this.active = active;
      this.orderQuantity = orderQuantity;
      this.specsN = specsN;
      this.shopTypeL = shopTypeL;
      this.type = type;
      this.componentsOL = componentsOL;
      this.descriptionT = descriptionT;
      this.recipeStepsT = recipeStepsT;
      this.otherComponentsOL = otherComponentsOL;
      this.videoLink = videoLink;
      this.addOnOptionsL = addOnOptionsL;
      this.priceOptions = priceOptions;
    }
  
    // Example toJson function to convert the model to JSON format
    toJson() {
      return {
        itemID: this.itemID,
        nameT: this.nameT,
        avPrice: this.avPrice,
        img: this.img,
        serves: this.serves,
        ingredientsTL: this.ingredientsTL,
        specsL: this.specsL,
        pCatL: this.pCatL,
        tags: this.tags,
        chefId: this.chefId,
        chefName: this.chefName,
        premium: this.premium,
        cookTime: this.cookTime,
        lastUpdate: this.lastUpdate,
        flex: this.flex,
        hasVideo: this.hasVideo,
        lang: this.lang,
        status: this.status,
        featured: this.featured,
        inPackages: this.inPackages,
        comment: this.comment,
        comments: this.comments,
        calories: this.calories,
        likes: this.likes,
        leverage: this.leverage,
        sortingV: this.sortingV,
        source: this.source,
        views: this.views,
        collectionSource: this.collectionSource,
        storeId: this.storeId,
        // position: this.position?.toJson(),
        price: this.price,
        priceB: this.priceB,
        discount: this.discount,
        active: this.active,
        orderQuantity: this.orderQuantity,
        specsN: this.specsN,
        shopTypeL: this.shopTypeL,
        type: this.type,
        // componentsOL: this.componentsOL.map(component => component.toJson()),
        descriptionT: this.descriptionT,
        recipeStepsT: this.recipeStepsT,
        // otherComponentsOL: this.otherComponentsOL.map(component => component.toJson()),
        videoLink: this.videoLink,
        // addOnOptionsL: this.addOnOptionsL.map(option => option.toJson()),
        // priceOptions: this.priceOptions?.toJson(),
      };
    }
  
    // Example fromJson function to create a model from a JSON object
    static fromJson(doc, docId) {
      return new PackageModel({
        documentId: docId,
        itemID: doc.itemID || docId,
        nameT: doc.nameT,
        avPrice: doc.avPrice,
        img: doc.img,
        serves: doc.serves,
        ingredientsTL: doc.ingredientsTL,
        specsL: doc.specsL,
        pCatL: doc.pCatL,
        tags: doc.tags,
        chefId: doc.chefId,
        chefName: doc.chefName,
        premium: doc.premium,
        cookTime: doc.cookTime,
        lastUpdate: doc.lastUpdate,
        flex: doc.flex,
        hasVideo: doc.hasVideo,
        lang: doc.lang,
        status: doc.status,
        featured: doc.featured,
        inPackages: doc.inPackages,
        comment: doc.comment,
        comments: doc.comments,
        calories: doc.calories,
        likes: doc.likes || 0,
        leverage: doc.leverage,
        sortingV: doc.sortingV,
        source: doc.source,
        views: doc.views,
        collectionSource: doc.collectionSource,
        storeId: doc.storeId,
        // position: doc.position ? PositionO.fromJson(doc.position) : null,
        price: doc.price,
        priceB: doc.priceB,
        discount: doc.discount,
        active: doc.active,
        orderQuantity: doc.orderQuantity || 0,
        specsN: doc.specsN,
        shopTypeL: doc.shopTypeL,
        type: doc.type,
        // componentsOL: doc.componentsOL ? doc.componentsOL.map(ComponentO.fromJson) : [],
        descriptionT: doc.descriptionT,
        recipeStepsT: doc.recipeStepsT,
        // otherComponentsOL: doc.otherComponentsOL ? doc.otherComponentsOL.map(ComponentO.fromJson) : [],
        videoLink: doc.videoLink,
        // addOnOptionsL: doc.addOnOptionsL ? doc.addOnOptionsL.map(AddOnOptions.fromJson) : [],
        // priceOptions: doc.priceOptions ? AddOnOptions.fromJson(doc.priceOptions) : null,
      });
    }
  
    // Example to convert this model to a product
    toProductO() {
      const newNameT = {};
      let optionNameT = {};
      let selectedOptId;
  
      this.priceOptions?.addOns?.forEach((element) => {
        if (element.selected) {
          optionNameT = element.nameT || {};
          selectedOptId = element.uuid;
        }
      });
  
      Object.keys(this.nameT || {}).forEach((key) => {
        newNameT[key] = `${(this.nameT || {})[key] || ""} ${(optionNameT[key] || "")}`;
      });
  
      return {
        uuid: this.itemID??"",
        nameT: newNameT??{},
        id: this.itemID??"",
        selectedOptId: selectedOptId??"",
        quantity: this.orderQuantity??1,
        price: this.price??0,
        type: this.type || this.pCatL?.[0],
        storeId: this.storeId??"",
        isPackage: true,
        flex: this.flex??true,
        chefId: this.chefId??"",
        addOnOptionsL: this.addOnOptionsL??[],
      };
    }
  }

  export default PackageModel;

  