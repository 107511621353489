import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../provider/AppContext';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { functions,httpsCallable,Timestamp } from '../firebase';
// import { httpsCallable } from "firebase/functions";
import {addDoc, collection, doc,getDoc, } from "firebase/firestore";
import { firestore } from '../firebase.js';



const CheckoutScreen = ({storeProductO, onClose }) => {
    const subTotal = storeProductO.productsOL.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(subTotal??0);
    const [orderModel, setOrderModel] = useState({});
    const [gateWayResultL, setGateWayList] = useState([]);
    
    const [promoCode, setPromo] = useState('');
    const [orderNote, setOrderNote] = useState('');
    const { state } = useContext(AppContext);  // Access global state

    const handlePromoInputChange = (e) => {
        const value = e.target.value;
        setPromo(value);
      };
      const handleNoteInputChange = (e) => {
        const value = e.target.value;
        setOrderNote(value);
      };
    
    const callStoreOrderCheckout = async (values) => {
        
        const callable = httpsCallable(functions, 'storeOrdercheckOut');
        try {
          const resp = await callable(values); // Call the function with the values
          if (resp?.data?.toString().length > 0) {
            console.log("Response data:", resp.data);
            return resp.data;
          }
        } catch (e) {
          console.error("Error calling storeOrderCheckOut:", e);
        }
        
        return null;
      };
      
      // Usage example
      const values = {
        "userType": 'customers',
        "userId": state.userModel?.id??"",
        "productsOL": storeProductO?.productsOL??[],
        "orderType": storeProductO?.orderType??"",
        "position": state.userModel?.position??{},
        "storeId": storeProductO?.storeId??"",
        "specsN": state.userModel?.specsN??"",
        "promoCode": promoCode??"",
        "orderNote":orderNote
      };
      
      
      
    const handleStoreOrderFunc = async () => {
      setLoading(true)
       await callStoreOrderCheckout(values).then((result) => {
            if (result) {
              console.log("Checkout result:", result);
              const feesOL = result?.feesOptionsOL??[]
              const deliveryPrice = result?.deliveryPrice??0
              const deliveryCost = result?.deliveryCost??0
              const totalFeesOL = feesOL.reduce((acc, item) => acc + item.cost , 0);
              setTotal(subTotal + deliveryPrice + totalFeesOL)
              setOrderModel({
                "userType": 'customers',
                "userId": state.userModel?.id??"",
                "productsOL": storeProductO?.productsOL??[],
                "orderType": storeProductO?.orderType??"",
                "position": state.userModel?.position??{},
                "storeId": storeProductO?.storeId??"",
                "specsN": state.userModel?.specsN??"",
                "promoCode": promoCode??"",

                "orderFeesOL":feesOL,
                "deliveryPrice":deliveryPrice,
                "deliveryCost":deliveryCost
              })
              setGateWayList(result.gateWayResultL)
            }
          });
          setLoading(false)
    }

    useEffect(() => {
        console.log('here 00')
        handleStoreOrderFunc();
      }, [ ]);

     const scheduleTime = Timestamp.now()
    const confirmCurrentOrder = async ()=>  {
      if(loading == false) {

        const newSpecs = [];
    //   const newSpecsM = storeModel?.specsM??{};

      newSpecs.push(state?.userModel?.addressO?.specsN ?? "egypt");
      newSpecs.push((state?.userModel?.addressO?.specsN ?? "egypt") + "-" +(storeProductO.orderType?.toLowerCase()??""));
      newSpecs.push('customers' + (state?.userModel?.id??""));
      newSpecs.push('customers' + (state?.userModel?.id??"") + "-" +(storeProductO.orderType?.toLowerCase()??""));
    //   newSpecs.push(storeModel?.specsL??[]);
    //   storeModel?.specsL??[].forEach((element) => {
    //   newSpecs.add(element + "-" +(storeProdO.orderType?.toLowerCase()??""));
    //   });
      newSpecs.push('stores' + (storeProductO?.storeId??""));
      newSpecs.push('stores' + (storeProductO?.storeId??"") + "-" + (storeProductO.orderType?.toLowerCase()??""));

      const uuidValue = "kjhl"
      const currentTimestamp = Timestamp.now();
      
      const newOrder = {
        uuid: uuidValue,
        cstId: state.userModel?.id??"",
        cstName: state.userModel?.name??"",
        cstLang: state.userModel?.lang??"",
        storeId: storeProductO?.storeId??"",
        storeAssignedId: storeProductO?.storeId??"",
        storeNameT: storeModel?.nameT??{},
        productsOL: orderModel?.productsOL??[],
        pickedProductsOL:[],
        premiumOL: [],
        ordersCount: 1,
        orderType: storeProductO?.orderType??"",
        table: storeProductO?.table??null,
        totalOrderCost: total,
        deliveryCost: orderModel.deliveryCost,
        deliveryPrice: orderModel.deliveryPrice,
        pickedProductsOM: [],
        storeAddressO: storeModel?.addressO,
        cstAddressO: state.userModel?.addressO,
        specsL: newSpecs,
        specsM: {},
        specsN: storeModel?.specsN??"",
        lastMsgId: '',
        active: true,
        activeInStore:true,
        inStore: true,
        inDelivery: false,
        status: 1,
        vehicle: 0,
        submitTime: currentTimestamp,
        lastUpdate: currentTimestamp,
        statusUpdates: [{
            status: 1,
            lastUpdate: currentTimestamp,
            scheduledOn: scheduleTime
          }],
        scheduledOn: scheduleTime,
        position: storeModel?.position,
        driverId: '',
        returned: false,
        deliverByStore: false,
        edited: false,
        driversAppliedOL: [],
        storeSeen: false,
        orderNote: orderNote,
        acceptReplacement: false,
        forceNewOrder: true,
        packageOL: [],
        orderFeesOL: orderModel?.orderFeesOL??[],
        internal: false,
        onCreateCalled: false,
        createTime: currentTimestamp,
        promoCode: promoCode
      }
      
    
      if ((newOrder?.cstId??"")!="" &&(state.user.uid??"")!="" && (storeModel?.id??"") !="" && (state.userModel?.id??"") != "") {
        // sending order to the store
        if (state.user) {
            console.log("Order Model: ", newOrder);
            const collectionRef = collection(firestore, 'orders');
            await addDoc(collectionRef,  newOrder);
          }
      } else {
        console.log("Order Model cst: ", orderModel.cstId);
      }

      }
      
    }
    const [storeModel, setStoreModel] = useState(null);


    
  
    const cashGateWay = {name_en:"Cash", name_ar:"Cash" , logo:"",payment_Id:""}
    const [selectedPayment, setSelectedPayment] = useState(cashGateWay);
    const changeSelectedPayment = (paymentSelected) => {
      setSelectedPayment(paymentSelected);
    }

    useEffect(() => {
        console.log('here 00')
        const fetchStores = async () =>  {
          try {
          const theId = (storeProductO?.storeId)
          console.log("cart query stores:" +  theId);
          const q = doc(firestore, 'stores', theId);
      
          const storeDocument = await  getDoc(q);
      
          const storeData =    storeDocument.data()
            
            console.log("Fetched cart stores:", (storeData.nameT??{}['en']));
            const stores  = storeData;
            
            setStoreModel(stores);
            
          } catch (err) {
            setError(err.message);
            
          }
        };
      
        console.log('here 0')
          
        fetchStores();
        
      }, [loading, ]);

    return (
    <Box
      sx={{
        // width: '100%',
        // maxWidth: '500px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <IconButton onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: '10px' }}>
          Check out
        </Typography>
      </Box>

      {/* Delivery Address */}
      <Box
        sx={{
          padding: '15px',
          borderRadius: '8px',
          backgroundColor: '#f7f7f7',
          marginBottom: '10px',
        }}
      >
        
        <Box style={{width: '100%',}} 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
        <Typography variant="body1" fontWeight="bold">
          {(state.userModel?.addressO?.cityT??{})['en']}{", "}
          {(state.userModel?.addressO?.areaT??{})['en']}
        </Typography>
        <Button
          sx={{
            backgroundColor: 'yellow',
            color: 'black',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '2px 8px',
            marginTop: '8px',
          }}
        >
          Primary
        </Button>
        </Box >

        <Typography variant="body2">
        {(state.userModel?.addressO?.district??"")}{", "}
        {(state.userModel?.addressO?.st)}{", "}
        {(state.userModel?.addressO?.buildingNum)}{", "}
        {(state.userModel?.addressO?.floorNum)}{", "}
        {(state.userModel?.addressO?.apartmentNum)}{", "}
        {(state.userModel?.addressO?.landMark)}{", "}
        
        </Typography>
        <Typography variant="body2">Mobile : {state.userModel?.addressO?.phone??state.userModel?.phone??""}</Typography>
        
        
      </Box>

      {/* Order Summary */}
      <Box
        sx={{
          padding: '15px',
          borderRadius: '8px',
          backgroundColor: '#f7f7f7',
          marginBottom: '10px',
        }}
      >
        <Box
        sx={{
          
          marginBottom: '6px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={storeModel?.logo??"https://via.placeholder.com/50"}
          sx={{ width: 56, height: 56, marginRight: '10px' }}
        />
        <Box 
        sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h6">{(storeModel?.nameT??{})['en']}</Typography>
          <Typography variant="body2" color="primary">
            {storeProductO?.orderType??"Delivery"}
          </Typography>
        </Box>
        
      </Box>
      <Box 
        sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Subtotal </Typography>
          <Typography variant="h7">{subTotal/100} {" "}{storeModel?.currency??" LE"}</Typography>
        </Box>

        <Box 
        sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Delivery </Typography>
          <Typography variant="h7">{(orderModel?.deliveryPrice??0)/100} {" "}{storeModel?.currency??" LE"}</Typography>
        </Box>
      <Box>  
        {(orderModel?.orderFeesOL??[]).map((feeO, indexS) => (
              
              <Box 
                sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="h7"
                  style={{color:feeO.cost<0 ? "red":"black"}}
                  >{feeO?.name??""}</Typography>
                  <Typography variant="h7">{(feeO?.cost??0)/100} {" "}{storeModel?.currency??" LE"}</Typography>
                </Box>
            ))}
        
        <Box 
        sx={{ paddingLeft:"60px", flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h7">Total</Typography>
          <Typography variant="h7"> ${total/100} {" "}{storeModel?.currency??" LE"}</Typography>
        </Box>
        
      </Box>
        
        
      </Box>
      

      {/* Promo Code */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <TextField
            onChange={handlePromoInputChange}
          fullWidth
          label="Promo code"
          variant="outlined"
          sx={{marginRight: '10px' }}
        />
        <Button 
        onClick={handleStoreOrderFunc}
        variant="contained" color="primary">
          Apply
        </Button>
      </Box>

      {/* Note Input */}
      <TextField
        onChange={handleNoteInputChange}
        label="Note"
        multiline
        rows={1}
        variant="outlined"
        fullWidth
        sx={{ marginBottom: '20px' }}
      />

      {/* Accept Replacement Checkbox */}
      

      {/* Notice */}
      
      

      {/* Payment Method */}
      <Typography variant="h6" gutterBottom>
        Payment Method
      </Typography>
      <Button
      onClick={() => changeSelectedPayment(cashGateWay)}
        variant={selectedPayment.name_en == cashGateWay.name_en?"outlined":""}
        fullWidth
        sx={{
            padding: '5px',
            borderColor: '#17150c',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px',
            borderRadius: '25px',
          }}
      >
        <Avatar
          src="https://via.placeholder.com/40"
          alt="Cash Icon"
          sx={{ marginRight: '10px' }}
        />
        Cash
      </Button>

      {/* Payment Option - Visa/Mastercard */}
      {gateWayResultL.map((gateWat) => (
        <Button
        onClick={() => changeSelectedPayment(gateWat)}
        variant= {selectedPayment.name_en == gateWat.name_en? "outlined": ""}
        fullWidth
        sx= {{
          padding: '5px',
          borderColor: '#17150c',
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
          borderRadius: '25px',
        }}
      >
        <img 
              width={50} 
              src={gateWat?.logo??""}
              alt="Logo" 
              style={{ 
                marginLeft: '10px', 
                marginRight: '10px', 
                borderRadius: '10%'  // Circular image
              }} 
            />
        
        {gateWat.name_en}
        {/* paymentId */}
      </Button>
      ))}


      {/* Confirm Button */}
      <Button
        variant="contained"
        fullWidth
        onClick={confirmCurrentOrder}
        sx={{
          backgroundColor: loading !== true?"#ffd700":"#f7f7f7",
          // backgroundColor: '#f7f7f7',
          color: 'black',
          padding: '10px',
          fontSize: '16px',
          borderRadius: '8px',
        }}
      >
        Confirm
      </Button>

    </Box>
  );
};

export default CheckoutScreen;
