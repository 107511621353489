import React, { useContext, useEffect, useState } from 'react';
import {doc,getDoc, } from "firebase/firestore";
import { AppContext } from '../provider/AppContext';
import { firestore } from '../firebase.js';

import { Drawer, Divider,
   Box,  Typography, Button, 
   IconButton, List, ListItem, Avatar,ListItemText,ListItemAvatar
 } from '@mui/material';
 
 import AddIcon from '@mui/icons-material/Add';
 import RemoveIcon from '@mui/icons-material/Remove';
 import DeleteIcon from '@mui/icons-material/Delete';
 import CloseIcon from '@mui/icons-material/Close';
 import CheckoutScreen from '../screen/checkout';
 

 export const StoreProdObj = ({ storeProductsO }) => {
  const {state, updateCart , updateStoreProductOQuantity } = useContext(AppContext);  // Access global state
  const [storeModel, setStoreModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const handleAddToCart = () => {
  //   const newItem = { productId: 'item-123', name: 'Sample Item', quantity: 1 };
  //   updateCart(newItem);  // Add an item to the cart
  // };

  const handleAddToCart = ({item,  newQ}) => {
    // Add to cart logic
    // updateCart(storeId,orderType , item);
    updateStoreProductOQuantity(storeModel, item , {quantity: newQ , merge: true})
    // console.log(`Added ${newQ} x ${item.name} to the cart with size ${size}, add-ons: ${JSON.stringify(addOns)}, note: ${note}`);
    // handleClose(); // Close the modal after adding to cart
  };
  // const { state,  updateStoreProductOQuantity } = useContext(AppContext); // Access the cart state and the function to update the cart

  useEffect(() => {
    console.log('here 00')
    const fetchStores = async () =>  {
      try {
      const theId = (storeProductsO?.storeId)
      console.log("cart query stores:" +  theId);
      const q = doc(firestore, 'stores', theId);
  
      const storeDocument = await  getDoc(q);
  
      const storeData =    storeDocument.data()
        
        console.log("Fetched cart stores:", (storeData.nameT??{}['en']));
        const stores  = storeData;
        setLoading(false);
        setStoreModel(stores);
        
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
  
    console.log('here 0')
      
    fetchStores();
    
  }, [loading, ]);

  const subTotal = storeProductsO.productsOL.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const [checkoutVisible, setCheckoutVisible] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleCheckoutOpen = (storeId) => {
    if(state.userModel?.id){
      toggleDrawer(false)
      setCheckoutVisible(true); // Close the checkout screen
    } else {

    }
  };

  
  const handleCheckoutClose = () => {
    setCheckoutVisible(false); // Close the checkout screen
};

  return (
    <div>
        <Drawer key={"drawer"} anchor="right" 
        open={checkoutVisible} onClose={handleCheckoutClose}>
          <Box sx={{
              width: 400,
              padding: 2,
              backgroundColor: '#f5f5f5',
              height: '100%',
            }} >
            <CheckoutScreen key={storeProductsO.storeId+"checkout"} 
            storeProductO= {storeProductsO}  
            onClose={handleCheckoutClose} />
          </Box>
        </Drawer>

        <Box
          sx={{
            width: '100%',
            // maxWidth: '500px',
            margin:-1,
            padding: '10px',
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
    >
      {/* Cart Header */}
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          Your order
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box> */}

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <img
            src={storeModel?.logo ??"https://via.placeholder.com/50"} // Replace with actual store logo
            alt="Store Logo"
            style={{ width: '50px', height: '50px', borderRadius: '10%', marginRight: '10px' }}
          />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {(storeModel?.nameT ?? {})['en']}
          </Typography>
          <Button variant="text" color="primary">{storeProductsO?.orderType??"-"}</Button>
        </Box>

      {/* Cart Items */}
      <List>
        {storeProductsO.productsOL.map((item) => (
          <React.Fragment key={item.id}>
            <ListItem>
              {/* Product Image */}
              {(item.img??"")!==""?(<ListItemAvatar>
                <Avatar
                  variant="rounded"
                  src={item.img}
                  alt={item.nameT['en']}
                  sx={{ width: 56, height: 56 }}
                />
              </ListItemAvatar>):null}

              {/* Product Name and Price */}
              <ListItemText
                primary={item.nameT['en']}
                secondary={`${(item.price/100)*item.quantity}${storeModel?.currency??"LE"}`}
                sx={{ marginLeft: '10px' }}
              />

              {/* Quantity Controls */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: item.quantity > 0 ? 'rgba(0,0,255,0.05)' : 'transparent',
                  borderRadius: '12px',
                }}
              >
                {item.quantity > 0 ? (
                  <>
                    <IconButton 
                    onClick={() => handleAddToCart({item:item ,  newQ: -1})}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography sx={{ padding: '0 10px' }}>{item.quantity}</Typography>
                    <IconButton 
                    onClick={() => handleAddToCart({item:item ,  newQ: 1})}
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                ) : (
                  <Typography sx={{ padding: '0 10px' }}>{item.quantity}</Typography>
                )}

                {/* Delete Button */}
                <IconButton
                  onClick={() => handleAddToCart({item:item ,  newQ: -item.quantity})}
                  sx={{ marginLeft: '5px' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      
    
    <Button 
    onClick={() => handleCheckoutOpen()}
    variant="contained" color="primary" fullWidth>
          Go to Checkout {subTotal/100} {storeModel?.currency??'LE'}
        </Button>
        <div style={{
            margin:20
        }}>
        <Divider />
        </div>
    </Box>
      
      {/* Cart Items */}
      

        {/* {(storeProductsO?.productsOL??[]).map((prodO, index) => (
        <Typography key={index} variant="body2" gutterBottom>
            {prodO?.nameT['en']} (Quantity: {prodO.quantity})
        </Typography>
        ))} */}
        
        
    </div>
    
  );
};

