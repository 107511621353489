import React, { useContext, useState } from 'react';
import {Divider, Drawer, Box, Typography, Button } from '@mui/material';
import { AppContext } from '../provider/AppContext';
import { StoreProdObj } from '../widgets/storeProductO';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import CheckoutScreen from './checkout';
 export const CartSliderScreen = ({ isOpen, toggleDrawer }) => {
  const { state,  } = useContext(AppContext);  // Access global state
  const [isProductModalOpen, setProductModalOpen] = useState(false);
  

  
  // const [checkoutVisible, setCheckoutVisible] = useState(false);



  return (
    <div>
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
       <Box
         sx={{
           width: 400,
           padding: 2,
           backgroundColor: '#f5f5f5',
           height: '100%',
         }}
       >
         
         {/* Location */}
         <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#040230', padding: '20px', marginTop: -2,marginRight: -2,marginLeft: -2, marginBottom: '10px' }}>
           <LocationOnIcon sx={{ color: 'white', marginRight: '0px', marginTop: '0px' }} />
           <Typography style={{color: 'white'}} variant="body2">39F7+5Q Nasr City, Egypt</Typography>
         </Box>
 
         
 
         
 
         {/* Cart Items */}
         {state?.needLst?.storeProductsOL?.length === 0 ? (
           <Typography variant="body1" gutterBottom>
             Your cart is currently empty.
           </Typography>
         ) : (
 
           <Box>
             {(state.needLst?.storeProductsOL??[]).map((storeProductsOb, indexS) => (
               
               <Box>
                <StoreProdObj
                  // setCheckoutVisible= {() => {handleCheckoutOpen(storeProductsOb.storeId)}}
                  
                  key={storeProductsOb.storeId +"storeProd"} storeProductsO={storeProductsOb}/>

                  {/* <Drawer key={storeProductsOb.storeId+"drawer"} anchor="right" open={checkoutVisible} onClose={handleCheckoutClose}>
                    <Box sx={{
                        width: 400,
                        padding: 2,
                        backgroundColor: '#f5f5f5',
                        height: '100%',
                      }} >
                      <CheckoutScreen key={storeProductsOb.storeId+"checkout"} storeProductO= {storeProductsOb}  onClose={handleCheckoutClose} />
                    </Box>
                  </Drawer> */}
                
               </Box>
             ))}
           </Box>
           
         )}
         
         
       </Box>
     </Drawer>
    </div>
    
  );
};

