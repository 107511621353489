import React ,{useState, useContext, useEffect} from 'react';

import {IconButton, Box, Card, CardContent, CardMedia, Typography, Button, 
  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AppContext } from '../provider/AppContext';




  const ProductCard = ({ product , isPackages = true, onItemTapped , storeModel }) => {
    const imgLinkURL = "https://firebasestorage.googleapis.com/v0/b/naslst.appspot.com/o/";
    const img = isPackages? (product.img) : imgLinkURL +(product.img)
    const name = (product?.nameT??{})['en']
     
    const {state,needLst, updateStoreProductOQuantity } = useContext(AppContext);  // Access global state
    const [quantity, setQuantity] = useState(0); // Quantity state

    
    useEffect(() => {
      console.log('here 00')
      const checkQuantity = async () =>  {
        try {
          (state.needLst.storeProductsOL??[]).forEach(element => {
            if(element.storeId == storeModel.id){
              (element.productsOL??[]).forEach(theProduct => {
                if(product.id == theProduct.id){
                  setQuantity(theProduct.quantity??0)
                }
              });
            }
          });
        
        } catch (err) {
          
        }
      };
    
      console.log('here 0')
      checkQuantity();
    }, [state, needLst]);

    const handleAdd = (event) => {
      event.stopPropagation(); // Prevent card click when add button is pressed
      setQuantity(quantity + 1); // Increase the quantity
      updateStoreProductOQuantity(storeModel, product , {quantity: 1 , merge: true})
    };
  
    const handleRemove = (event) => {
      event.stopPropagation(); // Prevent card click when remove button is pressed
      if (quantity > 0) {
        setQuantity(quantity - 1); // Decrease the quantity
        updateStoreProductOQuantity(storeModel, product , {quantity: -1 , merge: true})
      }
    };

    return (
      <Card
      onClick={onItemTapped}  style={{ cursor: 'pointer' }} 
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: '250px', // Ensures a square aspect ratio
          padding: 1,
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' },
        }}
      >
        {/* Product Image */}
        {(img??"")!="" ?(<CardMedia
          component="img"
          height= {(img??"")!="" ?"150":""}
          image={img}
          alt={product.nameT['en']}
          sx={{ borderRadius: '8px', objectFit: 'contain' }}
        />):<Box height={25}/>}
  
        {/* Product Info */}
        <CardContent sx={{ textAlign: 'center', padding: '16px 0 8px' }}>
          <Typography variant="body2" color="textSecondary">
            {product.price/100} {storeModel?.currency??'LE'}
          </Typography>
          <Typography 
          
          variant="body1" noWrap>
            {(product?.nameT??{})['en']}
          </Typography>
        </CardContent>
  
        
        {/* Quantity Controls */}
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: quantity > 0 ? 'white' : 'transparent',
            borderRadius: '12px',
            // padding: '4px',
          }}
        >
          {quantity === 0 ? (
            <IconButton
              size="small"
              onClick={handleAdd}
              sx={{
                backgroundColor: '#9897ad',
                '&:hover': { backgroundColor: '#FFD31A' },
              }}
            >
              <AddIcon style={{
                color: 'white'
              }}/>
            </IconButton>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,255,0.05)',
                borderRadius: '8px',
                padding: '4px',
              }}
            >
              <IconButton
                size="small"
                onClick={handleRemove}
                sx={{
                  backgroundColor: 'rgba(0,0,255,0.05)',
                  '&:hover': { backgroundColor: 'gray' },
                }}
              >
                <RemoveIcon />
              </IconButton>
              <Typography 
              style={{
                color:'black'
              }} sx={{ padding: '0 8px' }}>{quantity}</Typography>
              <IconButton
                size="small"
                onClick={handleAdd}
                sx={{
                  backgroundColor: '#FFD31A',
                  '&:hover': { backgroundColor: 'white' },
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Card>
    );
    return (
                <div  >
                <Card 
                onClick={onItemTapped}  style={{ cursor: 'pointer' }} 
                >
                
                <CardMedia
                    component="img"
                    height="140"
                    image= {product.image??img}
                    alt={product.name}
                />
                <CardContent>
                    
                    <Typography variant="h6"
                      style={{
                        // fontWeight:'bold',
                        whiteSpace: 'nowrap',        // Prevents text from wrapping to the next line
                        overflow: 'hidden',          // Hides the overflowing content
                        textOverflow: 'ellipsis',    // Adds '...' at the end of overflowing text
                      }}
                    >{name??product.name}</Typography>
                    <Typography variant="body2" color="textSecondary"
                      style={{
                        whiteSpace: 'nowrap',        // Prevents text from wrapping to the next line
                        overflow: 'hidden',          // Hides the overflowing content
                        textOverflow: 'clip',    // Adds '...' at the end of overflowing text
                      }}
                    >{(product?.descriptionT??{})['en']}</Typography>
                    <Typography variant="body2" color="textSecondary">
                    Price: {(product.price??0)/100}
                    </Typography>
                    {/* <Button 
                    onClick={onIncrease}
                    variant="contained" color="primary">
                    Add to Cart
                    </Button> */}
                </CardContent>
                </Card>
                </div>
            
            
            
        );
};



  export default ProductCard;