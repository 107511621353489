// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber , PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from 'firebase/storage';


// After initializing the auth instance
// const authO = getAuth();

// authO.settings.appVerificationDisabledForTesting = true; // Disable for testing

const firebaseConfig = {
    apiKey: "AIzaSyBpr8B1J3bx1cscY5N_iKMhMSh2Qitfk5U",
    authDomain: "naslst.firebaseapp.com",
    databaseURL: "https://naslst.firebaseio.com",
    projectId: "naslst",
    storageBucket: "naslst.appspot.com",
    messagingSenderId: "1020942622356",
    appId: "1:1020942622356:web:faac6bc13484982a2d1e81",
    measurementId: "G-2VGY2LGTN2"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and export it
const auth = getAuth(app);
const storage = getStorage(app);

const firestore = getFirestore(app);
const functions = getFunctions(app);

export {Timestamp, functions, 
  httpsCallable, auth, 
  RecaptchaVerifier, signInWithPhoneNumber,
   firestore as firestore , PhoneAuthProvider , 
  signInWithCredential,storage};
