// import logoo from './logo512.png';
// import React from 'react';
import './App.css';
import StoreScreen from './screen/StoreScreen';
import LoginScreen from './screen/login';
import { AppProvider } from './provider/AppContext'; // Import the AppProvider
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StoreSearch from './screen/storeSearch';

// import HomePage from './HomePage';
// import PhoneAuth from './PhoneAuth';
// import StoreScreen from './StoreScreen';



function App() {
  console.log('here App011')
  // const storeScreen = React.useMemo( () => 
    // <StoreScreen key={"storeScreenHome1"} theId= {"3aish.w.salt"}  />, [] );

  
  const theme = createTheme({
    palette: {
      primary: {
        // main: '#f7c441',  // Your custom primary color (hex code)
        main: '#04032B',  // Your custom primary color (hex code)
      },
      secondary: {
        // main: '#17150c',  // Optional: customize the secondary color
        main: '#04032B',  // Optional: customize the secondary color
      },
    },
  });
  // <AppProvider></AppProvider>
  {/* <UserProfile /> */}
      {/* <StoreScreen key={"storeScreenHome1"} theId= {"dietdelighteg"}  /> */}
      {/* <PhoneAuth   /> */}

  return (
    <ThemeProvider theme={theme}>
          <AppProvider>
          <Router>
            <Routes>
              {/* <Route exact path="/" component={PhoneAuth} /> */}
              {/* <Route exact path="/b/:username" component={PhoneAuth} /> */}
              {/* <Route component={PhoneAuth} /> */}
              
                <Route path="/" element={<StoreSearch />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/b/:username"  element={<StoreScreen  />} />
                <Route path="*" element={<LoginScreen />} />
            </Routes>
        </Router>
        </AppProvider>
      </ThemeProvider>
      
     
    
    
    
    
  );
}

export default App;
