import React, { useState, useEffect , useRef , useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Divider,List, ListItem, ListItemText, Container, Drawer, Box,  Typography, Button, AppBar, Toolbar, TextField, IconButton,
    Modal, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox , useMediaQuery,
      Slide
 } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// import { Modal, Box, Typography, Button, Grid, IconButton, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ProductCard from '../widgets/product.js';
import {doc,getDoc, query, collection, where,    getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import { firestore } from '../firebase.js';

import { AppContext } from '../provider/AppContext'; // Import the context
import { useParams } from 'react-router-dom';
import { CartSliderScreen } from './cart.js';
// import { cleanSearchTerm, neatSearchTerm } from '../business/functions.js'
import { Login, Merge } from '@mui/icons-material';
import pattern_web from '../asset/pattern_web.png';  // Import the image like a module
import CheckoutScreen from './checkout.js';
import ScreenModal from '../widgets/popUp.js';
import LoginScreen from './login.js';
import ChatWindow from './chat.js';

import Dialog from  '../widgets/Dialog.js';
import ProductPage from './productPage.js';
import LeftDrawer from '../leftDrawer.js';
import MapWithLocation from './MapWithLocation.js';






// import React from 'react';
// import { Box, Typography, Container } from '@mui/material';

// Mock product data for demonstration
const mockProducts = [
  { id: 1, name: "Product 1", price: 2000, image: "https://via.placeholder.com/150" },
  { id: 2, name: "Product 2", price: 5000, image: "https://via.placeholder.com/150" },
  // Add more products here
];

const coverImage = "https://via.placeholder.com/1200x300"; // Replace with actual cover image URL
const logoImage = "https://via.placeholder.com/100"; // Replace with actual logo URL



  

  // const LeftDrawer = ({ isOpen, toggleDrawer }) => {
  //   // List of options in the drawer
  //   const menuOptions = [
  //     { text: "My Profile" },
  //     { text: "Settings" },
  //     { text: "Messages" },
  //     { text: "Sign Out" }
  //   ];
  
  //   return (
  //     <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
  //       <Box
  //         sx={{
  //           width: 250,
  //           padding: '10px',
  //           backgroundColor: '#f5f5f5',
  //           height: '100%',
  //         }}
  //         role="presentation"
  //         onClick={toggleDrawer(false)} // Close the drawer when an item is clicked
  //         onKeyDown={toggleDrawer(false)} // Close when user presses a key
  //       >
  //         <List>
  //           {menuOptions.map((option, index) => (
  //             <ListItem button key={index}>
  //               <ListItemText primary={option.text} />
  //             </ListItem>
  //           ))}
  //         </List>
  //       </Box>
  //     </Drawer>
  //   );
  // };

  
  
const StoreScreen = () => {

  const { username } = useParams();
  const theId = username;
  const TheProduct = {
    name: 'Sample Product',
    price: 19.99,
    image: 'https://via.placeholder.com/400',
  };

  const [products, setProductsL] = useState([]);
  const [searchProducts, setSearchProductsL] = useState([]);
  
  const [lastDocument, setLastDoc] = useState(null);
  const [product, setProduct] = useState(TheProduct);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storeModel, setStoreModel] = useState(null);
  const [activeCode, setActiveCode] = useState(0);
  const [storeConfig, setStoreConfig] = useState(null);
  // let storeConfig;
  // const [categoriesL, setCategoriesL] = useState([]);
  console.log('here 011')
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Function to toggle the cart drawer
  const toggleCart = (open) => (event) => {
    setIsCartOpen(open);
  };

  const [isChatVisible, setIsChatVisible] = useState(false);

  // Toggle the visibility of the chat window
  const toggleChat = () => {
    setIsChatVisible(!isChatVisible);
  };

  const closeChat = () => {
    setIsChatVisible(false);
  };



  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Function to toggle the drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };


  const [isProductModalOpen, setProductModalOpen] = useState(false);
  
  const [isScreentModalOpen, setScreentModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  

  // Example product data
  // let product = {
  //   name: 'Sample Product',
  //   price: 19.99,
  //   image: 'https://via.placeholder.com/400',
  // };

  
  const handleOpenProductModal = ({prod}) => {
    setProduct(prod)
    setProductModalOpen(true);
    
  };

  

  const handleCloseProductModal = () => {
    setProductModalOpen(false);
  };

  const handleCloseScreenModal = () => {
    setScreentModalOpen(false);
  };

  const setActiveScreenModal = () => {
        setActiveModal(<MapWithLocation key={"login"} />)
      setScreentModalOpen(true)
  };

  const setActiveScreenModalProduct = (product) => {
    setActiveModal(
    <ProductPage 
      key={product?.id??"id" }
      product={product}
      storeModel={storeModel}
      handleClose={handleCloseScreenModal}
      openCart={toggleCart(true)}
     />)
    setScreentModalOpen(true)
};



  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // const CustomContent = () => (
  //   <div>
  //     <LoginScreen></LoginScreen>
  //   </div>
  // );
  const CustomContent = () => (
    <div>
      <h2>Custom Alert</h2>
      <p>This is custom content passed to the dialog.</p>
    </div>
  );

  

  const getProductsByCategories = async ({storeId , code}) => {
    if(searchTerm === ''){
      try {
        console.log("getting Store prods ", storeId);
        setActiveCode(code)
        const q = query(collection(firestore, "stores", storeId, 'storePackages')
        , where("pCatL", "array-contains", code)
        , where("active", "==", true)
        
        , orderBy("sortingV", "desc")
        , limit(20)
       );
  
        const querySnapshot = await  getDocs(q);
    
        const listData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        if (listData.length > 0) {
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length-1])
          // Document data found
          console.log("ProductsL :" +  (listData.length.toString()));
          setProductsL(listData)
          if(listData.length == 20){
            setHasMore(true)
          }
        } else {
          console.log("no prod");
          setLastDoc(null)
          setProductsL([])
          setHasMore(false)
          // No document found with this ID
          // console.log("No such document!");
        }
  
      } catch (err) {
        console.log('error while getting productsL')
        console.log(err.message)
        setError(err.message);
        setLoading(false);
      }
    }
    
  };

//   let storeModel ;

  useEffect(() => {
    console.log('here 00')
    const fetchStores = async () =>  {
      try {
      console.log("query stores:" +  theId);
      const q = query(collection(firestore, 'stores'), where("username", "==", theId));
  
      const querySnapshot = await  getDocs(q);
  
      const storeData =    querySnapshot.docs.map(doc => ({
        // id: doc.id,
        ...doc.data(),
      }));

      if ((storeData?.length??0) > 0) {
        console.log("Fetched stores:", storeData);
        const stores  = storeData;
        setLoading(false);
         if ((stores?.length??0) > 0) {
            console.log("found more than one sotres" + (stores.length.toString()));
            setStoreModel(stores[0]);
            // console.log("found more than one sotres" + storeModel.nameT['en']);
        }
      } else {
        console.log("query stores by id:" +  theId);
        const q2 = doc(firestore, 'stores', theId);
        const querySnapshot2 = await  getDoc(q2);
        if((querySnapshot2?.data()?.id??"") != ""){
          setStoreModel(querySnapshot2.data());
        }        
      }
        
        
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
  
    console.log('here 0')
    const fetchStorConfig = async ({storeId}) => {
      try {
        console.log("getting Store config for ", storeId);
        const storeDocRef = doc(firestore, "storesConfig", storeId);
      
        // Fetch the document
        const storeDoc = await getDoc(storeDocRef);
    
        if (storeDoc.exists()) {
          // Document data found
          console.log("Store config:", storeDoc.data());
          setStoreConfig(storeDoc.data())
          // storeConfig = storeDoc.data()
        } else {
          // No document found with this ID
          console.log("No such document!");
        }
    
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    


      
      const fetchByOrders = async () => {
        await fetchStores();
        if ((storeModel?.id??"").length > 0) {
          console.log('there is a store here')
          console.log('now we will get storeConfg')
          console.log('now we will get the categories')
          await fetchStorConfig({storeId : storeModel?.id??""});
          console.log('now we will get the products')
          await getProductsByCategories({storeId:storeModel?.id??"" , code : activeCode})
        } else {
          console.log('empty store')
        }
        }

    fetchByOrders();
    
  }, [loading, username,]);


  
  
const allCat = {nameT: {"en":"All", "ar":"All", }, code:0}
  let UseCatType = () => {return storeConfig?.useCatType??true }
  
   let TheCategories = () => {
    let result  = []
    if (UseCatType() === true && (storeConfig?.storeTypeL?.length??0) > 0) {
      storeConfig.activeStoreTypeL = (storeConfig?.storeTypeL??[]).filter(item => item.active);

      result = ((storeConfig?.activeStoreTypeL??[]).map((storeType, index) => (
         (storeType) 
      )));
      // result.push(resultL)
      // result = result 
    }
    return [allCat , ...result]  ;
   };


   
   
  //  const [products, setProducts] = useState(mockProducts);
  const [hasMore, setHasMore] = useState(true);

  // Function to simulate loading more products
  const fetchMoreProducts = () => {
    if (searchTerm === '') {
      setTimeout( async () => {
        let listData = []
        let querySnapshot;
        if (lastDocument != null && storeModel.id != null) {
          const q = query(collection(firestore, "stores", storeModel.id, 'storePackages')
          , where("pCatL", "array-contains", activeCode)
          , where("active", "==", true)
          , orderBy("sortingV", "desc")
          , limit(20)
          , startAfter(lastDocument)
         );
    
          querySnapshot = await  getDocs(q);
      
           listData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));  
        }
        
  
        if ((listData?.length??0) > 0) {
          console.log('addings product to the list')
          setProductsL([...products, ...listData]);
          const latestDoc = querySnapshot?.docs[(querySnapshot?.docs?.length??0) -1]
          setLastDoc(latestDoc)
        } else {
          if (lastDocument != null ) {
            console.log('no products founded')
            setHasMore(false);
          }
  
        }
        
        if (lastDocument != null && listData.length < 20) { // Stop loading after 40 products
          console.log('latest products < 0')
          setHasMore(false);
        }
      }, 30);
    } else {

    }

  };

  const gradients = [
    'linear-gradient(45deg, #FFD700, #FFA500)',
    'linear-gradient(45deg, #FF6347, #FF4500)',
    'linear-gradient(45deg, #32CD32, #228B22)',
    // Add more gradient pairs as needed
  ];

  const [hovered, setHovered] = useState(false);
  const headerStyle = {
    padding:  '20px',
    margin: 10,
    textAlign: 'center',
    backgroundColor: 'primary',  // Example background color
    transition: 'box-shadow 0.3s ease',  // Smooth transition for the shadow
    boxShadow: hovered
      ? '0 8px 10px rgba(0, 0, 0, 0.6)'  // Shadow when hovered
      : '0 2px 4px rgba(0, 0, 0, 0.1)',  // Light shadow by default
  };

  const headerStyle2 = {
    position: 'absolute',  // Make Header absolutely positioned
    top: 0,
    left: 0,
    right: 0,
    padding: '0px',
    
    backgroundColor: 'transparent',  // Set background color to make it distinct
    boxShadow: 
    hovered
      ? '0 8px 10px rgba(0, 0, 0, 0.6)'  // Shadow when hovered
      : '0 2px 4px rgba(0, 0, 0, 0.1)'
    ,  // Add shadow
    zIndex: 2,  // Ensure it overlays Header23
  };



  // Header content
  const [showAppBar, setShowAppBar] = useState(true);  // Control visibility of AppBar
const [lastScrollY, setLastScrollY] = useState(0);   

const handleScroll = () => {
  const currentScrollY = window.scrollY;

  if (currentScrollY > lastScrollY) {
    // If scrolling down, hide the AppBar
    setShowAppBar(false);
  } else {
    // If scrolling up, show the AppBar
    setShowAppBar(true);
  }

  setLastScrollY(currentScrollY);  // Update the last scroll position
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [lastScrollY]);



const [searchTerm, setSearchTerm] = useState('');
const cleanSearchTerm = (searchTerm) => {
  // Step 1: Trim leading/trailing spaces
  const trimmedTerm =  (" " + searchTerm + " ").toLowerCase()
  .replaceAll("أ", "ا")
  .replaceAll("ي ", "ى ")
  .replaceAll("ة", "ه")
  .replaceAll("إ", "ا")
  .replaceAll("آ", "ا")
  .replaceAll("   ", " ")
  .replaceAll("  ", " ")
  .replaceAll(" and ", " ")
  .replaceAll(" And ", " ")
  .replaceAll(" & ", " ")
  .replaceAll(" و ", " ")
  .trim();

  // Step 2: Split the string by space into an array
  const splitTerms = trimmedTerm.split(' ');

  // Step 3: Filter out any empty strings caused by multiple spaces
  const cleanedTerms = splitTerms.filter(term => term !== '');
  
  // Step 4: Return the cleaned array of terms
  return cleanedTerms;
};



const searchStoreProducts = async (term) => {
  if (term === '') {

    return;
  }
  console.log(cleanSearchTerm(term))

  setLoading(true);
  try {
    // Query Firestore to search stores by name

    let storeResults = []
    let storeResults2 = []
    let storeResults3 = []
    

    
    if(false  && storeResults2.length == 0) {
      const q3 = query(
        collection(firestore, 'stores', storeModel.id, 'storePackages'),
          where('active', '==', true),
          where('nameT.en', '>=', term ),
          where('nameT.en', '<=', term + '\uf8ff'),// Range search for partial matches
          limit(20)  // 'stores' is your collection in Firestore
        );
  
        const querySnapshot3 = await getDocs(q3);
         storeResults3 = querySnapshot3.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
    }

    if (storeResults2.length == 0) {
      const q = query(
        collection(firestore, 'stores', storeModel.id, 'storePackages'),
          where('active', '==', true),
          where('tags', 'array-contains-any', cleanSearchTerm(term)),
          limit(20)  // 'stores' is your collection in Firestore
        );

  
        const querySnapshot = await getDocs(q);
        storeResults = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
    }
    const allList = [...storeResults2,  ...storeResults3, ...storeResults, ]
  //   const uniqueValues = [...new Set(allList)];
    const uniqueValues = [
      ...new Map(allList.map(item => [item.id, item])).values()
    ];
      // Step 2: Sort the values
      const sortedValues = uniqueValues.sort((a, b) => {
      // If 'a' matches the search key, place it first
      if (a.nameT['en'] === (term)) return -1  
      // If 'b' matches the search key, place it first
      if (b.nameT['en'] === (term)) return 1;
      // If 'a' matches the search key, place it first
      if (a.nameT['en'].includes(term)) return -1  
      // If 'b' matches the search key, place it first
      if (b.nameT['en'].includes(term)) return 1;
      
      if ((a.username??"").includes(term)) return -1;
      // Otherwise, sort alphabetically
      if ((b.username??"").includes(term)) return 1;

      if ((a.tagsL??[]).includes(term)) return -1;
      // Otherwise, sort alphabetically
       if ((b.tagsL??[]).includes(term)) return 1;

      return (a.id).localeCompare(b?.id);
      });
      

    setSearchProductsL(sortedValues);

  } catch (error) {
    console.error('Error fetching stores:', error);
  } finally {
    setLoading(false);
  }
};

const handleInputChange = (e) => {
  const value = e.target.value;
  setSearchTerm(value);
  searchStoreProducts(value);  // Trigger Firestore query on input change
};


// const {state , setCheckoutVisible} = useContext(AppContext);  // Access global state
const [checkoutVisible, setCheckoutVisible] = useState(false);
const handleCheckoutClose = () => {
  setCheckoutVisible(false); // Close the checkout screen
};

const isSmallScreen = useMediaQuery('(max-width: 800px)'); // Adjust screen size for responsiveness

const storeName = (storeModel?.nameT??{})['en']
  return (
    <div  className="background-section">
      {/* <div
      style={headerStyle2}
      onMouseEnter={() => setHovered(true)}  // Add shadow on hover
      onMouseLeave={() => setHovered(false)} // Remove shadow when not hovered
    >
      <h1>Header</h1>
    </div> */}
    <div style={headerStyle2} 
    onMouseEnter={() => setHovered(true)}  // Add shadow on hover
    onMouseLeave={() => setHovered(false)} // Remove shadow when not hovered
    >
    {/* <Header StoreModel={storeModel}  key= {"storeHeader1"}/> */}
    <Slide direction="down" in={showAppBar}>
      <AppBar position="fixed" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img 
              width={40} 
              src={storeModel?.logo ?? "https://via.placeholder.com/50"} 
              alt="Logo" 
              style={{ 
                marginRight: '10px', 
                borderRadius: '10%'  // Circular image
              }} 
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
              style={{ 
                marginBottom: '-8px'  }}
              >{(storeModel?.nameT ?? {})['en']}</span>
              <span  style={{ 
                 marginBottom: '2px' ,
                fontSize: '0.6em', color: 'gray' }}>@{storeModel?.username ?? 'Unknown User'}</span>  {/* Username */}
            </div>
            
          </div>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search"
            onChange={handleInputChange}
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '5px', marginRight: '10px' }}
          />
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
        </Toolbar>
        </AppBar>
    </Slide>
    </div>
    
    
      
    { searchTerm === ''? (
      
      <div>        
      {/* <Header23 key= {"storeHeader2"} storeModel = {storeModel}/> */}
      <Box>
        {/* Cover Photo */}
        <Box
          sx={{
            height: 300,
            backgroundImage: `url(${storeModel?.img??coverImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
          }}
        >
          {/* Optional overlay for dimming the cover photo */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay with transparency
            }}
          />
        </Box>
  
        {/* Logo, Store Name, and Buttons */}
        <Container sx={{ marginTop: '10px', position: 'relative', zIndex: 1 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {/* Logo and Store Name */}
              <Grid container alignItems="center">
                <Grid item>
                  {/* Store Logo */}
                  <Box
                    component="img"
                    src={storeModel?.logo??logoImage}
                    alt="Store Logo"
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: '50%',
                      border: '4px solid white',
                      backgroundColor: 'white',
                      marginRight: 2,
                    }}
                  />
                </Grid>
                <Grid item>
                  {/* Store Name */}
                  <Typography variant="h4" color="textPrimary">
                  {storeName}
                  </Typography>
                  {/* <Typography variant="body1"
                   color="textSecondary">
                    @{storeModel?.username??""} 
                    - {storeModel?.followers??""} Followers
                  </Typography> */}
                  <Typography variant="body1"
                   color="textSecondary">
                    @{storeModel?.username??""} 
                    {" - "} {storeModel?.saves??""} saves
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
  
            {/* Follow and Message Buttons */}
            <Grid item>
              <Button
              onClick={setActiveScreenModal}
                variant="contained"
                color="primary"
                // backgroundImage: 'linear-gradient(to bottom,  #FFD700, #ffffff)', 
                sx={{ marginRight: '10px' }}
              >
                Save
              </Button>
              {/* <Button variant="outlined" color="primary">
                Message
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <ScreenModal 
      key={storeModel?.id}
        open = {isScreentModalOpen}
        handleClose={handleCloseScreenModal}
      >
        {activeModal}
      </ScreenModal>


      <div
        style={{
          display: 'flex',
          overflowX: 'auto', // Enables horizontal scrolling
          whiteSpace: 'nowrap', // Prevents items from wrapping to the next line
          padding: '10px',
          // backgroundColor: '#ffe08',
          backgroundImage: 'linear-gradient(to bottom,  #04032B, black)', 
          
        }}
      >
        {
        TheCategories().map((category, index) => (
          <Button 
          onClick={() => getProductsByCategories({storeId:storeModel?.id??"" , code : category.code}) }

            key= {index}
            style={{
              margin: '0 10px',
              color: '#ffffff',
              // fontWeight: 'bold',
              // backgroundColor: '#ffe08',
              backgroundImage: activeCode == category.code ? 'linear-gradient(to bottom, #04032B, #ffffff )' : null,
              flexShrink: 0, // Ensures the buttons don’t shrink when the container is too small
            }}
          >
            {(category?.nameT??{})['en']}
          </Button>
        ))}
      </div>
      </div>
      
      ) : (<div/ > ) }
      {(searchTerm !=="") ? (<div style={{height:60}}></div>): null}

      {/* Add the rest of your page content */}
      {/* <CategoryBar2 storeConfig = {storeConfig}/> */}
      
      {/* <div style={{ padding: '20px' }}>
        <ProductGrid storeModel = {storeModel} />
      </div> */}
      <div style={{padding: '20px' }}>
        <InfiniteScroll
        dataLength={(searchTerm == ''? products:searchProducts).length}
        next={fetchMoreProducts}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage= {<p style={{ textAlign: 'center' }} >Powered by NASLST</p>}
      >
        <Grid container spacing={2}>
          {(searchTerm == ''?products:searchProducts).map((product) => (
            <Grid item xs={6} sm={6} md={3} lg={2} key={product.id}>
              <ProductCard key={product.id} product={product} storeModel={storeModel} 
              // onItemTapped = {handleOpenProductModal}
              onItemTapped={() => setActiveScreenModalProduct(product) }
              // onIncrease={() => handleOpenProductModal({prod:product}) }
              // onDecrease= {handleOpenProductModal}
              />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
      </div>
      

      {/* Cart slider drawer */}

      <IconButton
        onClick={toggleCart(true)}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
        aria-label="cart"
      >
        <ShoppingCartIcon fontSize="large" />
      </IconButton>

      <div>
      
      
      
    </div>


      <div>
      {/* Menu Button to open drawer */}
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{ position: 'fixed', top: '20px', left: '20px', zIndex: 1000 }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      {/* Left Drawer */}
      <LeftDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      
      {/* Rest of your page content */}
      {/* <div style={{ padding: '20px' }}>
        <h1>Welcome to the Home Page</h1>
        <p>Content goes here...</p>
      </div> */}
    </div>

      <div>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleChat}
        sx={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 999 }}
      >
        Chat
      </Button>

      {/* Chat Window */}
      <ChatWindow storeModel={storeModel} isVisible={ isChatVisible} closeChat={closeChat} />
    </div>
      <CartSliderScreen key={storeModel?.id??""} isOpen={isCartOpen} toggleDrawer={toggleCart} />
    
      {/* <Drawer key={"drawer"} anchor="right" 
        open={state.checkoutVisible} onClose={handleCheckoutClose}>
          <Box sx={{
              width: 400,
              padding: 2,
              backgroundColor: '#f5f5f5',
              height: '100%',
            }} >
            <CheckoutScreen key={"checkout"} 
            storeProductO= {state.checkOutStoreProduct}  
            onClose={handleCheckoutClose} />
          </Box>
      </Drawer> */}
    </div>
    
  );
};

//   return (
//     <div>
//       <Header />
//       <Header2 />
//       <CategoryBar2 />
//       <div style={{ padding: '20px' }}>
//         <ProductGrid />
//       </div>
//     </div>
//   );
// };

export default StoreScreen;
